import React, { useEffect, useState } from 'react'
import { SEO } from '@renderbus/common/components'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'

import Ticket1Png from '../images/cloud-rendering-promotion/ticket1.png'
import Ticket2Png from '../images/cloud-rendering-promotion/ticket2.png'
import Advantage1Png from '../images/cloud-rendering-promotion/advantage1.png'
import Advantage2Png from '../images/cloud-rendering-promotion/advantage2.png'
import Advantage3Png from '../images/cloud-rendering-promotion/advantage3.png'
import EffectShowPng from '../images/cloud-rendering-promotion/effect_show.png'
import AnimationPng from '../images/cloud-rendering-promotion/animation_show.png'

import aboutFour1 from '../images/cloud-rendering-promotion/about_four1.png'
import AboutFour2 from '../images/cloud-rendering-promotion/about_four2.png'
import AboutFour3 from '../images/cloud-rendering-promotion/about_four3.png'
import AboutFour4 from '../images/cloud-rendering-promotion/about_four4.png'

import left1Bg from '../images/cloud-rendering-promotion/left1.webp'
import left2Bg from '../images/cloud-rendering-promotion/left2.jpg'
import left3Bg from '../images/cloud-rendering-promotion/left3.webp'
import mid1Bg from '../images/cloud-rendering-promotion/mid1.jpg'
import mid2Bg from '../images/cloud-rendering-promotion/mid2.webp'
import mid3Bg from '../images/cloud-rendering-promotion/mid3.webp'
import right1Bg from '../images/cloud-rendering-promotion/right1.jpg'
import right2Bg from '../images/cloud-rendering-promotion/right2.webp'
import right3Bg from '../images/cloud-rendering-promotion/right3.jpg'

import Company1Png from '../images/cloud-rendering-promotion/company1.png'
import Company2Png from '../images/cloud-rendering-promotion/company2.png'
import Company3Png from '../images/cloud-rendering-promotion/company3.png'
import Company4Png from '../images/cloud-rendering-promotion/company4.png'
import Company5Png from '../images/cloud-rendering-promotion/company5.png'
import Company6Png from '../images/cloud-rendering-promotion/company6.png'
import Company7Png from '../images/cloud-rendering-promotion/company7.png'
import Company8Png from '../images/cloud-rendering-promotion/company8.png'
import Company9Png from '../images/cloud-rendering-promotion/company9.png'
import Company10Png from '../images/cloud-rendering-promotion/company10.png'

import effect1Png from '../images/cloud-rendering-promotion/effect1.png'
import Effect2Png from '../images/cloud-rendering-promotion/effect2.png'
import Effect3Png from '../images/cloud-rendering-promotion/effect3.png'
import Effect4Png from '../images/cloud-rendering-promotion/effect4.png'
import Effect5Png from '../images/cloud-rendering-promotion/effect5.png'
import Animation1Png from '../images/cloud-rendering-promotion/animation1.png'
import Animation2Png from '../images/cloud-rendering-promotion/animation2.png'
import Animation3Png from '../images/cloud-rendering-promotion/animation3.png'
import Animation4Png from '../images/cloud-rendering-promotion/animation4.png'
import Animation5Png from '../images/cloud-rendering-promotion/animation5.png'

import mayaIcon from '../images/cloud-rendering-promotion/software_maya.png'
import _3dsMaxIcon from '../images/cloud-rendering-promotion/software_3dmax.png'
import cinema4dIcon from '../images/cloud-rendering-promotion/software_cinema4d.png'
import blenderIcon from '../images/cloud-rendering-promotion/software_blender.png'
import unrealEngineIcon from '../images/cloud-rendering-promotion/software_unreal_engine.png'
import vVayIcon from '../images/cloud-rendering-promotion/software_v_ray.png'
import redShiftIcon from '../images/cloud-rendering-promotion/software_redshift.png'
import arnoldIcon from '../images/cloud-rendering-promotion/software_arnold.png'
import coronaIcon from '../images/cloud-rendering-promotion/software_corona.png'
import renderManIcon from '../images/cloud-rendering-promotion/software_renderman.png'
import animaIcon from '../images/cloud-rendering-promotion/software_anima.png'
import forestPackIcon from '../images/cloud-rendering-promotion/software_forest_pack.png'
import railCloneIcon from '../images/cloud-rendering-promotion/software_rail_clone.png'
import xParticlesIcon from '../images/cloud-rendering-promotion/software_x_particles.png'
import miarmyIcon from '../images/cloud-rendering-promotion/software_miarmy.png'
import octaneIcon from '../images/cloud-rendering-promotion/software_octane.png'

import emblemPng from '../images/cloud-rendering-promotion/emblem.png'

import {
  BannerContainer,
  BannerWapper,
  BannerTicketBox,
  BannerSubTitle,
  BannerTitle,
  TicketBox,
  TicketTextBox,
  TicketType,
  TicketText,
  TicketImg,
  PropagandaTitle,
  AdvantageBox,
  AdvantageContent,
  ClientContainer,
  ClientWarpper,
  AdvantageDetail,
  Detail,
  FeaturesBox,
  Features,
  FeaturesTitle,
  FeaturesItemBox,
  FeaturesItem,
  PriceBox,
  PriceItem,
  ImgBox,
  PriceIdentity,
  PriceShow,
  PriceContent,
  PriceDetail,
  RegisteButton,
  IconBox,
  IconItem,
  SmallPropagandaTitle,
  AbooutContainer,
  AboutTitle,
  AboutWapper,
  AboutText,
  AboutFour,
  AboutFourItem,
  CenterTitle,
  GifBox,
  GifListBox,
  NewSmallPropagandaTitle,
  CompanyBox,
  CompanyItem,
  Register,
  RegisterTitle,
  RegisterSubTitle,
  RegisterButton,
  RegisteButtonBox,
  BottomBox,
  Billboard,
} from './cloud-rendering-promotion.atom'

import { Anchor } from '@renderbus/common/components'

import { isTest } from '@renderbus/common/service'

const companyImgArr1 = [Company1Png, Company2Png, Company3Png, Company4Png, Company5Png]

const companyImgArr2 = [Company6Png, Company7Png, Company8Png, Company9Png, Company10Png]

const aboutArr = [
  {
    img: aboutFour1,
    title: '高规格服务水平',
    subTitle: '奥斯卡作品云渲染团队',
  },
  {
    img: AboutFour2,
    title: '7*24小时',
    subTitle: '在线技术支持',
  },
  {
    img: AboutFour3,
    title: '可定制化',
    subTitle: '为项目提供定制服务',
  },
  {
    img: AboutFour4,
    title: '专属TD团队',
    subTitle: '为您的项目保驾护航',
  },
]

const iconArr = [
  {
    id: 1,
    img: mayaIcon,
    software: 'Maya',
  },
  {
    id: 2,
    img: _3dsMaxIcon,
    software: '3ds Max',
  },
  {
    id: 3,
    img: cinema4dIcon,
    software: 'Cinema 4d',
  },
  {
    id: 4,
    img: blenderIcon,
    software: 'Blender',
  },
  {
    id: 5,
    img: unrealEngineIcon,
    software: 'Unreal Engine',
  },
  {
    id: 6,
    img: vVayIcon,
    software: 'V-Ray',
  },
  {
    id: 7,
    img: redShiftIcon,
    software: 'Redshift',
  },
  {
    id: 8,
    img: arnoldIcon,
    software: 'Arnold',
  },
  {
    id: 9,
    img: coronaIcon,
    software: 'Corona',
  },
  {
    id: 10,
    img: renderManIcon,
    software: 'RenderMan',
  },
  {
    id: 11,
    img: octaneIcon,
    software: 'Octane',
  },
  {
    id: 12,
    img: animaIcon,
    software: 'anima®',
  },
  {
    id: 13,
    img: forestPackIcon,
    software: 'Forest Pack',
  },
  {
    id: 14,
    img: railCloneIcon,
    software: 'RailClone',
  },
  {
    id: 15,
    img: xParticlesIcon,
    software: 'X-Particles',
  },
  {
    id: 16,
    img: miarmyIcon,
    software: 'Miarmy',
  },
]

const effectFeaturesArr = [
  {
    name: '一键发布作业',
    img: effect1Png,
  },
  {
    name: '素材自动高速上传',
    img: Effect2Png,
  },
  {
    name: '多作业同时渲染',
    img: Effect3Png,
  },
  {
    name: '实时查看进度',
    img: Effect4Png,
  },
  {
    name: '自动回传至本地',
    img: Effect5Png,
  },
]

const animationtFeaturesArr = [
  {
    name: '一键批量提交',
    img: Animation1Png,
  },
  {
    name: '自动分析资产',
    img: Animation2Png,
  },
  {
    name: '分层相机渲染',
    img: Animation3Png,
  },
  {
    name: '实时预览渲染进度',
    img: Animation4Png,
  },
  {
    name: '客户端支持Windows、Linux',
    img: Animation5Png,
  },
]

function GoodePromotion() {
  function onScrollTop() {
    window.scrollTo({
      top: 0,
    })
  }
  function toTask(event) {
    if (event.data.message === 'neo-activity-page') {
      window.location.href = isTest()
        ? 'http://task-pre.renderbus.com/'
        : 'https://task.renderbus.com/'
    }
  }
  useEffect(() => {
    window.addEventListener('message', toTask, true)

    return () => {
      window.removeEventListener('message', toTask, true)
    }
  }, [])

  const [parentUrl, setParentUrl] = useState('')

  useEffect(() => {
    const handleMessage = event => {
      if (!['https://task-pre.renderbus.com', 'https://task.renderbus.com'].includes(event.origin))
        return
      setParentUrl(window.location.href)
      event.source.postMessage({ parentUrl: window.location.href }, event.origin)
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [parentUrl])

  const data = useStaticQuery(graphql`
    query {
      banner2Bg: file(relativePath: { eq: "cloud-rendering-promotion/banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        URL='https://www.renderbus.com/cloud-rendering-promotion.html'
        title='Renderbus瑞云渲染-强大的三维动画&建筑效果图一站式云渲染农场'
        description='Renderbus瑞云渲染，三维动画、建筑效果图一站云渲染农场，快！稳定！实惠！安全！立即注册最高可领取40元无门槛云渲染券，免费体验云渲染的魅力. '
        keywords='云渲染农场，瑞云渲染，云渲染'
      ></SEO>
      <Helmet>
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
              // 360统计代码
              (function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
})(window,document,"script","_qha",359342,false);
              // clarity 热力图
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "k4r0i3gxzc");
            `}
        </script>
      </Helmet>
      {/* 第一部分 */}
      <BannerContainer>
        <Billboard fluid={data.banner2Bg.childImageSharp.fluid} alt=''></Billboard>
        <BannerWapper>
          <div className='left'>
            <BannerTitle>
              <span style={{ color: '#13CB75' }}>Renderbus</span>瑞云渲染
              <br />
              您强大的一站式云渲染农场
            </BannerTitle>
            <BannerSubTitle>
              三维动画/建筑效果图 一站渲染，<span>快！稳定！实惠！安全！</span>
            </BannerSubTitle>
            <BannerTicketBox>
              <TicketBox>
                <TicketImg>
                  <img src={Ticket1Png} alt='' />
                </TicketImg>
                <TicketTextBox>
                  <TicketType>效果图渲染券</TicketType>
                  <TicketText>注册送20元云渲染券</TicketText>
                </TicketTextBox>
              </TicketBox>
              <TicketBox>
                <TicketImg>
                  <img src={Ticket2Png} alt='' />
                </TicketImg>
                <TicketTextBox>
                  <TicketType>动画渲染券</TicketType>
                  <TicketText>注册送40元云渲染券</TicketText>
                </TicketTextBox>
              </TicketBox>
            </BannerTicketBox>
          </div>
          <div className='right'>
            <iframe
              id='iframe_register'
              title='register'
              src={
                isTest()
                  ? 'https://task-pre.renderbus.com/sso/register?hidden=1'
                  : 'https://task.renderbus.com/sso/register?hidden=1'
              }
              width='468px'
              height='720px'
              frameborder='0'
            ></iframe>
          </div>
        </BannerWapper>
        <PropagandaTitle>更快、更稳定的云渲染平台</PropagandaTitle>
        <AdvantageBox>
          <AdvantageContent>
            <img src={Advantage1Png} alt='' />
            <div>高弹性渲染资源</div>
          </AdvantageContent>
          <AdvantageContent>
            <img src={Advantage2Png} alt='' />
            <div>数据高度安全</div>
          </AdvantageContent>
          <AdvantageContent>
            <img src={Advantage3Png} alt='' />
            <div>无缝集成</div>
          </AdvantageContent>
        </AdvantageBox>
      </BannerContainer>
      {/* 第二部分 */}
      <ClientContainer>
        <ClientWarpper>
          <AdvantageDetail>
            <Detail>
              <p>强大的单集群超10000节点的云渲染平台</p>
              <p>灵活调度，随时可用</p>
            </Detail>
            <Detail>
              <p>瑞云渲染平台通过MPA旗下的TPN权威认证</p>
              <p>数据中心通过ISO27001安全认证</p>
              <p>拥有覆盖整个行业全球性内容安全保护计划</p>
            </Detail>
            <Detail>
              <p>在制作流程中集成Renderbus云渲染</p>
              <p>使用SDK几行代码即可实现</p>
            </Detail>
          </AdvantageDetail>
          <FeaturesBox className='left'>
            <img src={EffectShowPng} alt='' />
            <Features className='features'>
              <FeaturesTitle>效果图渲染客户端 简单易用</FeaturesTitle>
              <FeaturesItemBox>
                {effectFeaturesArr.map((item, index) => (
                  <FeaturesItem key={index}>
                    <img src={item.img} alt='' />
                    <p>{item.name}</p>
                  </FeaturesItem>
                ))}
              </FeaturesItemBox>
            </Features>
          </FeaturesBox>
          <FeaturesBox className='right'>
            <Features>
              <FeaturesTitle className='features'>建筑动画渲染 支持客户端/网页端</FeaturesTitle>
              <FeaturesItemBox>
                {animationtFeaturesArr.map((item, index) => (
                  <FeaturesItem key={index}>
                    <img className='animation' src={item.img} alt='' />
                    <p>{item.name}</p>
                  </FeaturesItem>
                ))}
              </FeaturesItemBox>
            </Features>
            <img src={AnimationPng} alt='' />
          </FeaturesBox>
          <PropagandaTitle>实惠价格</PropagandaTitle>
          <PriceBox>
            <PriceItem>
              <ImgBox>
                <span>效果图渲染</span>
              </ImgBox>
              <PriceContent>
                <PriceIdentity>非会员</PriceIdentity>
                <PriceShow>
                  <div className='left'>¥</div>
                  <div className='mid'>0.04</div>
                  <div className='right'>/分钟</div>
                </PriceShow>
                <PriceDetail>
                  <p>封顶价：25元/张</p>
                  <p>每小时收费2.4元</p>
                  <p>相当于24核高性能工作站</p>
                </PriceDetail>
              </PriceContent>
            </PriceItem>
            <PriceItem>
              <ImgBox>
                <span>效果图渲染</span>
              </ImgBox>
              <PriceContent>
                <PriceIdentity>会员</PriceIdentity>
                <PriceShow>
                  <div className='left'>¥</div>
                  <div className='mid'>0.03</div>
                  <div className='right'>/分钟</div>
                </PriceShow>
                <PriceDetail>
                  <p>封顶价：20元/张</p>
                  <p>每月免单4张图（不限金额）</p>
                </PriceDetail>
              </PriceContent>
            </PriceItem>
            <PriceItem className='price2_bg'>
              <ImgBox className='animation'>
                <span>动画渲染</span>
              </ImgBox>
              <PriceContent>
                <PriceIdentity>会员</PriceIdentity>
                <PriceShow>
                  <div className={`left special`}>
                    <span className='green_special'>¥</span>
                    <span>低至</span>
                  </div>
                  <div className={`mid green_special`}>0.1</div>
                  <div className='right'>/核时</div>
                </PriceShow>
                <PriceDetail>
                  <p>每小时收费2元</p>
                  <p>相当于20核高性能工作站</p>
                </PriceDetail>
              </PriceContent>
            </PriceItem>
            <PriceItem className='price3_bg'>
              <PriceContent style={{ marginTop: '67px' }}>
                <PriceIdentity>新用户</PriceIdentity>
                <PriceShow>
                  <div className='small_mid'>
                    <p>更多渲染优惠</p>
                    <p>欢迎注册咨询</p>
                  </div>
                </PriceShow>
                <PriceDetail>
                  <p>支持CPU、GPU渲染</p>
                </PriceDetail>
              </PriceContent>
            </PriceItem>
          </PriceBox>
          <RegisteButtonBox>
            <RegisteButton onClick={onScrollTop}>立即注册，免费渲染</RegisteButton>
          </RegisteButtonBox>
          <PropagandaTitle top={100}>兼容大部分主流软件和插件</PropagandaTitle>
          <IconBox>
            {iconArr.map((item, index) => (
              <IconItem key={index}>
                <div className='img_box'>
                  <img src={item.img} alt='' />
                </div>
                <div className='text'>{item.software}</div>
              </IconItem>
            ))}
          </IconBox>
          <SmallPropagandaTitle>可上传自定义插件</SmallPropagandaTitle>
        </ClientWarpper>
      </ClientContainer>
      {/* 第三部分 */}
      <AbooutContainer>
        <AboutWapper>
          <AboutTitle>关于 Renderbus瑞云渲染</AboutTitle>
          <AboutText>
            Renderbus瑞云渲染是深圳市瑞云科技股份有限公司旗下的渲染农场品牌，用户超20万，遍及50多个国家和地区，包括奥斯卡金像奖得主、知名的影视动画、视效、建筑可视化、游戏工作室，被誉为国内“自助式云渲染”的先行者，代表案例包括电影《长津湖》《长津湖之水门桥》《战狼2》《哪吒之魔童降世》《流浪地球》《流浪地球2》《封神第一部》和真人电视剧《三体》等。瑞云渲染平台拥有单集群超10,000节点的大规模算力池，可以为不同规模的项目提供高弹性、可定制化的云渲染服务。同时瑞云渲染也是Learn
            V-Ray，丝路视觉等大型CG培训及3D模型资源网官方指定合作渲染农场。
          </AboutText>
          <AboutFour>
            {aboutArr.map((item, index) => (
              <AboutFourItem key={index}>
                <img src={item.img} alt='' />
                <div className='text'>{item.title}</div>
                <div className='sub_text'>{item.subTitle}</div>
              </AboutFourItem>
            ))}
          </AboutFour>
          <CenterTitle>我们的案例如繁星般璀璨</CenterTitle>
          <GifBox>
            <GifListBox>
              <img className='left1' src={left1Bg} alt='' />
              <img className='left2' src={left2Bg} alt='' />
              <img className='left3' src={left3Bg} alt='' />
            </GifListBox>
            <GifListBox>
              <img className='mid1' src={mid1Bg} alt='' />
              <img className='mid2' src={mid2Bg} alt='' />
              <img className='mid2' src={mid3Bg} alt='' />
            </GifListBox>
            <GifListBox>
              <img className='right1' src={right1Bg} alt='' />
              <img className='right2' src={right2Bg} alt='' />
              <img className='right3' src={right3Bg} alt='' />
            </GifListBox>
          </GifBox>
          <NewSmallPropagandaTitle top={30}>篇幅有限，展示部分案例</NewSmallPropagandaTitle>
          <CompanyBox>
            {companyImgArr1.map((item, index) => (
              <CompanyItem key={index}>
                <img src={item} alt='' />
              </CompanyItem>
            ))}
          </CompanyBox>
          <CompanyBox top={19} bottom={119}>
            {companyImgArr2.map((item, index) => (
              <CompanyItem key={index}>
                <img src={item} alt='' />
              </CompanyItem>
            ))}
          </CompanyBox>
        </AboutWapper>
      </AbooutContainer>
      {/* 第四部分 */}
      <Register>
        <RegisterTitle top={50}>新用户注册，免费领取无门槛云渲染券</RegisterTitle>
        <RegisterSubTitle top={17}>畅享专业可靠的高速云渲染服务</RegisterSubTitle>
        <RegisterButton onClick={onScrollTop}>立即领取</RegisterButton>
      </Register>
      {/* 第五部分 */}
      <BottomBox>
        <img src={emblemPng} alt='' />
        <span>
          <Anchor href='https://www.rayvision.com' rel='nofollow'>
            © 2023深圳市瑞云科技股份有限公司{' '}
          </Anchor>
          <Anchor
            target='_blank'
            href='https://beian.miit.gov.cn/#/Integrated/index'
            rel='nofollow'
          >
            粤ICP备12028569号{' '}
          </Anchor>
          中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125
        </span>
      </BottomBox>
    </>
  )
}

export default GoodePromotion
